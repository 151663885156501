import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { Loader } from "react-feather";
import { axiosInstance } from "../../helpers/axios/axios";
import { LogisticsStorage } from "../../helpers/endpoints/api_endpoints";

const StorageLocation = ({ setCompanyList, updateStorage }) => {
  const [companiesList, setCompaniesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 20; // Define items per page

  useEffect(() => {
    fetchCompanies(currentPage);
  }, [currentPage, setCompanyList, updateStorage]);

  const fetchCompanies = (page) => {
    axiosInstance.get(`${LogisticsStorage}?page=${page}`).then(
      (response) => {
        console.log(response.data);
        setCompaniesList(response.data);
        setTotalPages(Math.ceil(response.data.count / itemsPerPage)); // Adjusted calculation based on actual items per page
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div>
      <div className="content container-fluid">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h5 className="">Storage List</h5>
        </div>
        <Table striped bordered hover className="text-center">
          <thead className="table-secondary">
            <tr>
              <th>No.</th>
              <th>Company Name</th>
              <th>Warehouse Address</th>
              <th>State</th>
              <th>Storage Location</th>
            </tr>
          </thead>
          <tbody>
            {companiesList?.count > 0 ? (
              companiesList?.results?.map((item, index) => (
                <tr key={item.id}>
                  <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                  <td>
                    {item.warehouse_details?.company_details?.name ||
                      "Not available"}
                  </td>
                  <td>{item.warehouse_details?.address || "Not available"}</td>
                  <td>{item.warehouse_details?.state || "Not available"}</td>
                  <td>{item.storage_location || "Not available"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">
                  {" "}
                  loading...{" "}
                  <Loader
                    className="feather-loader"
                    data-bs-toggle="tooltip"
                    title="feather-loader"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <Button
            variant="secondary"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StorageLocation;
