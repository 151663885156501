import { Select } from "antd";
import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";

import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  NextVTDocketNumberUrl,
  outwardForm3plUrl,
  products,
  stockUrl,
} from "../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const OutwardForm3PL = () => {
  const { userId } = useSelector((state) => state.account);
  const [clientList, setClientList] = useState([]);
  const navigate = useNavigate();
  const [companyList, setCompanyList] = useState([]);
  const [errors, setErrors] = useState({});
  const [productList, setProductList] = useState([]);
  const [stockList, setStockList] = useState([]);
  const [productQuantities, setProductQuantities] = useState({});
  const [nextVTDocketNumber, setNextVtDocketNumber] = useState();

  console.log(productQuantities);
  const [formData, setFormData] = useState({
    client: "",
    company: "",
    remarks: "",
    client_docket_number: "",
    client_invoice_number: "",
    client_invoice_value: "",
    vt_docket_number: "",
    mode: "",
    sender: "",
    vehicle_number: "",
    vehicle_type: "",
    driver_staff_name: "",
    om_number: "",
    activity_cost: "",
    from_address: "",
    from_pincode: "",
    from_contact: "",
    from_email: "",
    to_address: "",
    to_pincode: "",
    to_contact: "",
    to_email: "",
    added_by: userId,
    outward_products: [
      { product: "", weight: "", pieces: "", ow_product_name: "" },
    ],
  });
  console.log(formData);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      vt_docket_number: nextVTDocketNumber?.next_vt_docket_number || "",
    }));
  }, [nextVTDocketNumber]);

  // Fetch products data
  useEffect(() => {
    axiosInstance.get(`${products}`).then(
      (response) => {
        setProductList(response.data); // Set the productList state with fetched data
      },
      (error) => {
        console.log(error);
      }
    );
    axiosInstance.get(`${stockUrl}`).then(
      (response) => {
        console.log(response.data);
        setStockList(response.data); // Set the stockList state with fetched data
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (formData?.company) {
      axiosInstance.get(`${NextVTDocketNumberUrl}${formData?.company}/`).then(
        (response) => {
          console.log(response.data);
          setNextVtDocketNumber(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [formData?.company]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleSelectChange = (name, value, label) => {
    const selectedValue =
      name === "mode"
        ? label
        : value && name === "vehicle_type"
        ? label
        : value;

    setFormData({
      ...formData,
      [name]: selectedValue,
    });
    // if (errors[name]) {
    //   setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    // }
  };

  const handleItemChange = (index, field, value) => {
    const newItems = formData.outward_products.map((item, i) =>
      i === index
        ? {
            ...item,
            [field]: field === "pieces" ? parseInt(value, 10) : value,
          }
        : item
    );
    setFormData({ ...formData, outward_products: newItems });

    if (field === "product") {
      const selectedStock = stockList?.results?.find(
        (stock) => stock.stock.id === value
      );
      console.log(selectedStock);
      const quantity = selectedStock ? selectedStock.quantity : "N/A";
      console.log(quantity);
      setProductQuantities((prevQuantities) => ({
        ...prevQuantities,
        [index]: quantity,
      }));

      const selectedProduct = productList?.results.find(
        (product) => product.id === value
      );
      setFormData((prevFormData) => ({
        ...prevFormData,
        outward_products: newItems.map((item, i) =>
          i === index
            ? {
                ...item,
                ow_product_name: selectedProduct ? selectedProduct : "",
              }
            : item
        ),
      }));
    }

    console.log(newItems);
  };

  const handleAddItem = () => {
    setFormData({
      ...formData,
      outward_products: [
        ...formData.outward_products,
        { product: "", weight: "", pieces: "", ow_product_name: "" },
      ],
    });
  };

  const handleDeleteItem = (index) => {
    const newItems = formData.outward_products.filter((_, i) => i !== index);
    setFormData({ ...formData, outward_products: newItems });

    // Remove the quantity for the deleted item
    setProductQuantities((prevQuantities) => {
      const { [index]: _, ...rest } = prevQuantities;
      return rest;
    });
  };
  const handleBooleanChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);

    axiosInstance.post(`${outwardForm3plUrl}`, formData).then(
      (response) => {
        console.log(response.data);
        toast.success("Form submitted successfully");
        navigate("/inward-form-list");
        setFormData("");
      },
      (error) => {
        console.log(error);
        if (error?.response?.data?.client) {
          toast.error("Client details can not be empty");
        }
        if (error?.response?.data?.company) {
          toast.error("Company details can not be empty");
        }
        if (error?.response?.data?.outward_products) {
          toast.error("Please select a product ");
        }
        if (error?.response?.data?.error) {
          toast.error(error?.response?.data?.error);
        }
      }
    );
    // if (Object.keys(newErrors).length > 0) {
    //   console.log(newErrors);
    //   setErrors(newErrors);
    //   toast.error("Please fill out all required fields ");
    // } else {
    //   console.log(formData);

    // }
  };
  useEffect(() => {
    axiosInstance.get(`${LogisticsCompaniesURL}`).then(
      (response) => {
        setCompanyList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  console.log(companyList);
  const YesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const { Option } = Select;

  const VehicleType = [{ value: "1", label: "Truck" }];
  const ModeOptions = [
    { value: "1", label: "Air" },
    { value: "2", label: "Shipping" },
    { value: "3", label: "Surface" },
  ];
  const receiverName = [{ value: "1", label: "Albart" }];

  const ContentName = productList?.results?.map((product) => ({
    value: product.id,
    label: product.name,
  }));
  const ClientList = clientList?.results?.map((company) => ({
    value: company.id,
    label: company.name,
  }));
  const CompanyList = companyList?.results?.map((company) => ({
    value: company.id,
    label: company.name,
  }));
  console.log(ClientList);

  // fetch client data
  useEffect(() => {
    axiosInstance.get(`${LogisticsClientsURL}`).then(
      (response) => {
        console.log(response.data);
        setClientList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  return (
    <div>
      <>
        <Header />
        <AdminSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h2 className="card-title mb-0">Outward3PL Form</h2>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4 justify-content-around">
                        <div className="col-xl-5 me-4">
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Client Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <Select
                                className="form-control"
                                options={ClientList}
                                placeholder="Select Client Name"
                                onChange={(value) =>
                                  handleSelectChange("client", value)
                                }
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Company Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <Select
                                className="form-control"
                                options={CompanyList}
                                placeholder="Select Company Name"
                                onChange={(value) =>
                                  handleSelectChange("company", value)
                                }
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Docket Number
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="client_docket_number"
                                value={formData.client_docket_number}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Invoice Number
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="client_invoice_number"
                                value={formData.client_invoice_number}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Client Invoice Value
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="client_invoice_value"
                                value={formData.client_invoice_value}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              VT Docket Number
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="vt_docket_number"
                                value={formData.vt_docket_number}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Mode
                            </label>
                            <div className="col-lg-8">
                              <Select
                                className="form-control"
                                options={ModeOptions}
                                placeholder="Select Mode"
                                onChange={(value, option) =>
                                  handleSelectChange(
                                    "mode",
                                    value,
                                    option.label
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-5 ms-4">
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Sender<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="sender"
                                value={formData.sender}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Vehicle Number
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="vehicle_number"
                                value={formData.vehicle_number}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Vehicle Type
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="vehicle_type"
                                value={formData.vehicle_type}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Driver/Staff Name
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="driver_staff_name"
                                value={formData.driver_staff_name}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              OM Number<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="om_number"
                                value={formData.om_number}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Activity Cost
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-8">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="activity_cost"
                                value={formData.activity_cost}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Remarks
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="text"
                                className="form-control"
                                name="remarks"
                                value={formData.remarks}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4 justify-content-around">
                        <div className="col-xl-6">
                          <h4 className="card-title">From Address</h4>
                          {/* <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Person Name
                            </label>
                            <div className="col-lg-9">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="from_person"
                                value={formData.from_person}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div> */}
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Address<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-9">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="from_address"
                                value={formData.from_address}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Pincode<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-9">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="from_pincode"
                                value={formData.from_pincode}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Contact Number
                            </label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="from_contact"
                                value={formData.from_contact}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Email
                            </label>
                            <div className="col-lg-9">
                              <input
                                type="email"
                                className="form-control"
                                name="from_email"
                                value={formData.from_email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6 ps-5">
                          <h4 className="card-title">To Address</h4>
                          {/* <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Person Name
                            </label>
                            <div className="col-lg-9">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="to_person"
                                value={formData.to_person}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div> */}
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Address<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-9">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="to_address"
                                value={formData.to_address}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Pincode<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-9">
                              <input
                                required
                                type="text"
                                className="form-control"
                                name="to_pincode"
                                value={formData.to_pincode}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Contact Number
                            </label>
                            <div className="col-lg-9">
                              <input
                                type="text"
                                className="form-control"
                                name="to_contact"
                                value={formData.to_contact}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Email
                            </label>
                            <div className="col-lg-9">
                              <input
                                type="email"
                                className="form-control"
                                name="to_email"
                                value={formData.to_email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <h4 className="card-title">
                        Additional Activities{" "}
                        <span className="text-danger">*</span>
                      </h4>
                      <div className="row mb-4">
                        {[
                          "Freight",
                          "Abortive",
                          "Union_Charge",
                          "Packing",
                          "First_Floor",
                          "Accessories",
                          "Grouting",
                          "De Grouting",
                          "Dismantling",
                          "Others",
                        ].map((option) => (
                          <div className="col-lg-4 mb-3" key={option}>
                            <div className="input-block row align-items-center justify-content-center">
                              <label className="col-lg-3 col-form-label">
                                {option.replace("_", " ")}
                              </label>
                              <div className="col-lg-6">
                                <Select
                                  placeholder="Select"
                                  className="form-control"
                                  name={option}
                                  onChange={(value) =>
                                    handleBooleanChange(option, value)
                                  }
                                >
                                  {YesNoOptions.map((opt) => (
                                    <Option key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="input-block mb-3 row align-items-center justify-content-center">
                        <label className="col-lg-1 col-form-label">
                          Specify
                        </label>
                        <div className="col-lg-6">
                          <input
                            type="text"
                            className="form-control"
                            name="specify"
                            value={formData.specify}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="row mb-4 justify-content-around">
                        <div className="col-xl-10 mx-auto">
                          <h4 className="card-title">
                            Product Information
                            <span className="text-danger">*</span>
                          </h4>
                          {formData.outward_products.map((item, index) => (
                            <div
                              key={index}
                              className="row mb-3 align-items-center"
                            >
                              <div className="col-lg-3">
                                <Select
                                  className="form-control"
                                  options={ContentName}
                                  placeholder="Select Product"
                                  onChange={(value) =>
                                    handleItemChange(index, "product", value)
                                  }
                                />
                              </div>

                              <div className="col-md-1 mb-4">
                                <label>Quantity</label>
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  placeholder={
                                    productQuantities[index] || "No data"
                                  }
                                  value={productQuantities[index] || "No data"}
                                  readOnly
                                />
                              </div>
                              <div className="col-lg-3">
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  placeholder="Weight"
                                  value={item.weight}
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      "weight",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col-lg-3">
                                <input
                                  required
                                  type="text"
                                  className="form-control"
                                  placeholder="Pieces"
                                  value={item.pieces}
                                  onChange={(e) =>
                                    handleItemChange(
                                      index,
                                      "pieces",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col-lg-2">
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  onClick={() => handleDeleteItem(index)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          ))}
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleAddItem}
                          >
                            Add Product
                          </button>
                        </div>
                      </div>
                      <div className="text-end">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default OutwardForm3PL;
