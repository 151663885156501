import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  inwardFormUrl,
  LogisticsPOInward,
  LogisticsStorage,
} from "../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

import { Button, Modal } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { Select } from "antd";

const AddInwardModal = ({ show, handleClose, updateOutwardInList }) => {
  const [formData, setFormData] = useState(null);
  const [storageList, setStorageList] = useState([]);
  const [selectedStorageLocation, setSelectedStorageLocation] = useState(null); // Track selected storage location ID
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate(); // For navigation

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${LogisticsPOInward}${show}/`).then(
        (response) => {
          setFormData(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setFormData(null);
    }
  }, [show]);

  useEffect(() => {
    if (formData?.warehouse) {
      axiosInstance
        .get(`${LogisticsStorage}?warehouse=${formData?.warehouse}`)
        .then(
          (response) => {
            setStorageList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, [formData?.warehouse]);

  // Handle select change for storage location
  const handleSelectChange = (value) => {
    setSelectedStorageLocation(value); // Save the selected storage location ID
  };

  const { Option } = Select;

  // Directly map storageList (no need for .results here)
  const WarehouseList = storageList?.results?.map((location) => ({
    value: location.id,
    label: location.storage_location,
  }));

  const handleConfirmInward = (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions

    // Validate storage location selection
    if (!selectedStorageLocation) {
      toast.error(
        "Please select a storage location before confirming the inward process."
      );
      return;
    }

    setIsSubmitting(true); // Set submission status to true

    // Create a new object excluding client_invoice_date if it's empty
    const dataToSend = { ...formData };
    if (!dataToSend.client_invoice_date) {
      delete dataToSend.client_invoice_date;
    }

    // Remove items_po from dataToSend
    delete dataToSend.items_po;
    // Add po_inward field with formData.id
    dataToSend.po_inward = formData.id;

    // Map over formData.items_po to structure items as required
    dataToSend.items = formData.items_po.map((item) => ({
      name: item.name?.id, // Use items_po.name.id for name field
      number: item.number,
      weight: item.weight,
    }));

    // Add selected storage_location ID
    dataToSend.storage_location = selectedStorageLocation;

    console.log(dataToSend);

    // POST request to submit form data
    axiosInstance
      .post(inwardFormUrl, dataToSend)
      .then((response) => {
        toast.success("Form submission successful");

        // PATCH request to update LogisticsPOInward endpoint as completed
        axiosInstance
          .patch(`${LogisticsPOInward}${show}/`, { is_completed: true })
          .then(() => {
            toast.success("Inward confirmed ");
          })
          .catch((error) => {
            console.log("Error marking PO as completed:", error);
          });

        setFormData({}); // Reset form data
        navigate("/inward-form-list"); // Navigate to the list after success
        handleClose(); // Close modal after success
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.client) {
          toast.error("Client details cannot be empty");
        }
        if (error?.response?.data?.company) {
          toast.error("Company details cannot be empty");
        }
        if (error?.response?.data?.transport_type) {
          toast.error("Transport Type details cannot be empty");
        }
        if (error?.response?.data?.type) {
          toast.error("Type cannot be empty");
        }
      })
      .finally(() => {
        setIsSubmitting(false); // Reset submission status
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Inward</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to confirm this inward process?</p>
          <div className="input-block mb-3 row align-items-center">
            <label className="col-lg-3 col-form-label">Storage Location</label>
            <div className="col-lg-8">
              <Select
                className="form-control"
                showSearch
                placeholder="Select Storage Location"
                onChange={handleSelectChange} // Capture the selected storage location ID
                filterOption={(input, option) =>
                  option?.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {WarehouseList?.length > 0 ? (
                  WarehouseList?.map((location) => (
                    <Option key={location.value} value={location.value}>
                      {location.label}
                    </Option>
                  ))
                ) : (
                  <Option disabled>No storage locations available</Option>
                )}
              </Select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmInward}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddInwardModal;
