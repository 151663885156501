import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { axiosInstance } from "../../helpers/axios/axios";
import { LogisticsClientsURL } from "../../helpers/endpoints/api_endpoints";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Loader, PlusCircle } from "react-feather";
import { toast } from "react-toastify";

function ViewDetailsModal({
  show,
  handleClose,
  updateCompanyInList,
  removeCompanyFromList,
}) {
  const [formData, setFormData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${LogisticsClientsURL}${show}/`).then(
        (response) => {
          setFormData(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set isSubmitting to true
    axiosInstance.patch(`${LogisticsClientsURL}${show}/`, formData).then(
      (response) => {
        updateCompanyInList(response.data);
        setIsSubmitting(false); // Set isSubmitting to false
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false); // Set isSubmitting to false in case of error
        if (error?.response?.data?.name?.[0]) {
          toast.error(error?.response?.data?.name?.[0]);
        }
      }
    );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${LogisticsClientsURL}${show}/`).then(
      () => {
        removeCompanyFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Client Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>
                      Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      maxLength={100}
                      minLength={1}
                    />
                  </Form.Group>
                  <Form.Group controlId="formContactName">
                    <Form.Label>
                      Contact Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_name"
                      value={formData.contact_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formAddress">
                    <Form.Label>
                      Address<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formContactNumber">
                    <Form.Label>
                      Contact Number<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_number"
                      value={formData.contact_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formGstNumber">
                    <Form.Label>
                      GST Number<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="gst_number"
                      value={formData.gst_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  {/* <Form.Group controlId="formPrefix">
                    <Form.Label>Prefix</Form.Label>
                    <Form.Control
                      type="text"
                      name="prefix"
                      value={formData.prefix}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group> */}
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Save"}
                    </Button>
                  </Modal.Footer>
                </Form>
              ) : (
                <>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{formData.name || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Contact Name</th>
                        <td>{formData.contact_name || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>{formData.address || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Contact Number</th>
                        <td>{formData.contact_number || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>GST Number</th>
                        <td>{formData.gst_number || "N/A"}</td>
                      </tr>
                      {/* <tr>
                        <th>Prefix</th>
                        <td>{formData.prefix || "N/A"}</td>
                      </tr> */}
                      <tr>
                        <th>Created</th>
                        <td>{formData.created.slice(0, 10) || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Updated at</th>
                        <td>{formData.updated.slice(0, 10) || "N/A"}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                    {/* <Button variant="danger" onClick={openDeleteConfirmation}>
                      Delete
                    </Button> */}
                  </Modal.Footer>
                </>
              )}
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this company?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
function AddCompanyModal({ show, handleClose, setCompanyList }) {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    gst_number: "",
    contact_number: "",
    contact_name: "",
    prefix: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setFormData({
        name: "",
        address: "",
        gst_number: "",
        contact_number: "",
        contact_name: "",
        prefix: "",
      });
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    axiosInstance.post(LogisticsClientsURL, formData).then(
      (response) => {
        setCompanyList((prevList) => {
          return {
            ...prevList,
            count: prevList.count + 1,
            results: [response.data, ...prevList?.results],
          };
        });
        setIsSubmitting(false);
        toast.success("Successfully added company");
        handleClose();
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false);
        if (error?.response?.data?.name?.[0]) {
          toast.error(error?.response?.data?.name?.[0]);
        }
      }
    );
  };

  return (
    <Modal centered size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>
              Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>
              Address <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formGstNumber">
            <Form.Label>GST Number</Form.Label>
            <Form.Control
              type="text"
              name="gst_number"
              value={formData.gst_number}
              onChange={handleChange}
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formContactNumber">
            <Form.Label>
              Contact Number<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="contact_number"
              value={formData.contact_number}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formContactName">
            <Form.Label>
              Contact Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="contact_name"
              value={formData.contact_name}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const Clients = () => {
  const [show, setShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);
  const handleAddModalClose = () => setShowAddModal(false);
  const handleAddModalShow = () => setShowAddModal(true);

  useEffect(() => {
    fetchCompanies(currentPage);
  }, [currentPage]);

  const fetchCompanies = (page) => {
    axiosInstance.get(`${LogisticsClientsURL}?page=${page}`).then(
      (response) => {
        setCompaniesList(response.data);
        setTotalPages(Math.ceil(response.data.count / 20));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const updateCompanyInList = (updatedCompany) => {
    setCompaniesList((prevList) => {
      return {
        ...prevList,
        results: prevList?.results?.map((outward) =>
          outward.id === updatedCompany.id ? updatedCompany : outward
        ),
      };
    });
  };

  const removeCompanyFromList = (id) => {
    setCompaniesList((prevList) =>
      prevList?.results?.filter((company) => company.id !== id)
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h2 className="card-title">Client List</h2>

            <Button
              onClick={handleAddModalShow}
              variant="primary"
              className="btn btn-primary btn-sm p-2 d-flex align-items-center"
            >
              <PlusCircle
                className="feather-download me-1"
                data-bs-toggle="tooltip"
                title="feather-download"
              />
              Add Client
            </Button>
          </div>
          <Table striped bordered hover className="text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Address</th>
                <th>Number</th>
                <th>GST Number</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {companiesList?.count > 0 ? (
                companiesList?.results?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * 10 + index + 1}</td>
                    <td>{item.name || "Not available"}</td>
                    <td>{item.address || "Not available"}</td>
                    <td>{item.contact_number || "Not available"}</td>
                    <td>{item.gst_number || "Not available"}</td>
                    <td>{item.created.slice(0, 10) || "Not Available"}</td>
                    <td>
                      <Button
                        variant="primary"
                        className="btn btn-sm ms-1"
                        onClick={() => handleShow(item.id)}
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">
                    {" "}
                    loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
          <ViewDetailsModal
            show={show}
            handleClose={handleClose}
            updateCompanyInList={updateCompanyInList}
            removeCompanyFromList={removeCompanyFromList}
          />
          <AddCompanyModal
            show={showAddModal}
            handleClose={handleAddModalClose}
            setCompanyList={setCompaniesList}
          />
        </div>
      </div>
    </>
  );
};

export default Clients;
