export const baseURL = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "https://logistics.dlmindia.com/";

// Accounts endpoints
export const signUpUrl = "accounts/sign-up/";
export const googleLoginUrl = "accounts/o/google-oauth2/";
export const loginUrl = "accounts/login/";
export const logoutUrl = "accounts/logout/";
export const clientProfileUrl = "accounts/client-profile/";
export const vendorProfileUrl = "accounts/vendor-profile/";
export const verifyOTPUrl = "accounts/verify-otp-token/";
export const refreshTokenUrl = "accounts/token/refresh/";
export const forgotPasswordUrl = "accounts/reset_password/";
export const resetPasswordUrl = "accounts/reset_password_confirm/";

export const changePassUrl = "accounts/set_password/";
export const resendOTPUrl = "accounts/resend-otp-token/";
export const vendorGallery = "accounts/vendor-gallery/";
export const vendorVerification = "accounts/vendor-verification-request/";
export const departmentList = "accounts/department/";
export const designationList = "accounts/designation/";
export const createUser = "accounts/create-user/";
export const basicUserDetails = "accounts/basic-user-details/"; //will receive and id
export const employeeDetails = "accounts/employee/";

// employee endpoints
export const uploadDocuments = "employee/upload-document/";
export const documentList = "employee/document-list/";
export const namingList = "employee/naming-list/";
export const documentDetail = "employee/document-detail/"; //will receive and id
export const nameShareApi = "employee/name-share/"; //will receive and id

// logistics endpoints
export const inwardFormUrl = "logistics/logistics-inwards/";
export const outwardFormUrl = "logistics/outward/";
export const outwardForm3plUrl = "logistics/3pl-outward/";
export const products = "logistics/products/";
export const stockUrl = "logistics/stock/";
export const exportOutwardData = "logistics/export-outward-data/";
export const exportInWardData = "logistics/export-logistics-inward/";
export const LogisticsCompaniesURL = "logistics/companies/";
export const LogisticsProductsURL = "logistics/products/";
export const LogisticsClientsURL = "logistics/clients/";
export const LogisticsActivityPhoto = "logistics/activity-photo/";
export const LogisticsActivityPod = "logistics/activity-pod/";
export const LogisticsApprovalPdf = "logistics/approval-pdf/";
export const LogisticsApprovalPendingList = "logistics/approval-pending/";
export const LogisticsUserInfoUpdate = "accounts/users/update/";
export const LogisticsUserUrl = "accounts/users/";
export const LogisticsUserDesignations = "accounts/designations/";
export const LogisticsCreateUser = "accounts/users/create/";
export const LogisticsDailyCounts = "logistics/daily-counts/";
export const LogisticsMonthlyCounts = "logistics/monthly-counts/";
export const LogisticsSalesRegister = "logistics/sales-register/";
export const LogisticsPaymentRegister = "logistics/payment-register/";
export const LogisticsPendingPayment = "logistics/pending-payment/"; // receive sales id
export const LogisticsChangePassword = "accounts/change-password/";
export const LogisticsCalculateTotal =
  "logistics/sales-register/calculate_total/";
export const DocketDownloadUrl = "logistics/generate-pdf/"; //recieved outward id
export const ApprovalCounts = "logistics/approval-count/"; //recieved outward id
export const NextVTDocketNumberUrl = "logistics/next-vt-docket-number/"; //recieved company id
export const exportOutstandingReport = "logistics/export-sales-register/";
export const exportStockReport = "logistics/export-stock/";
export const exportPO = "logistics/export-logistics-po/";
export const LogisticsWarehouse = "logistics/warehouses/";
export const LogisticsStorage = "logistics/storages/";
export const LogisticsPOInward = "logistics/po-inward/";
export const LogisticsDocketNumber = "logistics/next-vt-docket-number/"; //{company_id}
export const LogisticsPODocketNumber = "logistics/next-po-docket-number/"; //{company_id}
export const LogisticsExportPOStocks = "logistics/export-po/";
export const LogisticsPOPdf = "logistics/generate-po-pdf/"; //{po_id}
export const LogisticsImportPO = "logistics/import-po-data/";
export const LogisticsReverseDocket = "logistics/reserve-docket-numbers/";
export const LogisticsDownloadMultiplePDFs =
  "logistics/download-multiple-pdfs/";
