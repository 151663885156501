import React, { useState } from "react";
import { Modal, Button, Form, Spinner, ProgressBar } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../helpers/axios/axios";
import { LogisticsImportPO } from "../../helpers/endpoints/api_endpoints";

const ImportModal = ({ show, handleClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New state for loader
  const [uploadProgress, setUploadProgress] = useState(0); // Progress status
  const navigate = useNavigate();

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.name.endsWith(".xlsx")) {
      setSelectedFile(file);
      setErrorMessage("");
    } else {
      setSelectedFile(null);
      setErrorMessage("Please upload a valid .xlsx file");
    }
  };

  // Handle file upload
  const handleFileUpload = async () => {
    if (!selectedFile) {
      setErrorMessage("Please select an xlsx file");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    setIsLoading(true); // Start the loader
    setUploadProgress(0); // Reset progress bar

    try {
      const response = await axiosInstance.post(
        `${LogisticsImportPO}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            // Set progress bar value
            const percentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentage);
          },
        }
      );
      console.log(response.data);
      setSuccessMessage(response.data.message);
      toast.success(response.data.message);
      setErrorMessage("");
      setSelectedFile(null);
      handleClose();
      navigate("/inward-purchase-order-list"); // Navigate after success
    } catch (error) {
      console.log(error);
      setErrorMessage("Failed to import data");
    } finally {
      setIsLoading(false); // Stop the loader
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Import Excel Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Choose .xlsx file to upload</Form.Label>
          <Form.Control
            type="file"
            onChange={handleFileChange}
            disabled={isLoading}
          />
          {errorMessage && (
            <div className="text-danger mt-2">{errorMessage}</div>
          )}
          {successMessage && (
            <div className="text-success mt-2">{successMessage}</div>
          )}
        </Form.Group>

        {isLoading && (
          <div className="text-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Uploading...</span>
            </Spinner>
            <span>Uploading... {uploadProgress}%</span>
            <ProgressBar now={uploadProgress} className="mt-3" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={isLoading}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleFileUpload}
          disabled={isLoading} // Disable button while loading
        >
          {isLoading ? "Uploading..." : "Upload"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportModal;
