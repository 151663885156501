import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsClientsURL,
  LogisticsCreateUser,
  LogisticsUserDesignations,
  LogisticsUserInfoUpdate,
  LogisticsUserUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Loader, PlusCircle } from "react-feather";
import { toast } from "react-toastify";
import { Select } from "antd";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import MySwal from "sweetalert2";
import ChangePasswordModal from "./changePasswordModal";
function ViewDetailsModal({
  show,
  handleClose,
  updateCompanyInList,
  removeCompanyFromList,
  mappedUsers,
}) {
  console.log(mappedUsers);
  const [formData, setFormData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${LogisticsClientsURL}${show}/`).then(
        (response) => {
          setFormData(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance.patch(`${LogisticsUserInfoUpdate}${show}/`, formData).then(
      (response) => {
        updateCompanyInList(response.data);
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${LogisticsClientsURL}${show}/`).then(
      () => {
        removeCompanyFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            User Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>
                      Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      maxLength={100}
                      minLength={1}
                    />
                  </Form.Group>
                  <Form.Group controlId="formContactName">
                    <Form.Label>
                      Email<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_name"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formAddress">
                    <Form.Label>
                      Designation<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="address"
                      value={formData.designation}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formContactNumber">
                    <Form.Label>
                      Is Staff<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_number"
                      value={formData.is_staff}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formGstNumber">
                    <Form.Label>
                      Is Admin<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="gst_number"
                      value={formData.admin}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  {/* <Form.Group controlId="formPrefix">
                    <Form.Label>Prefix</Form.Label>
                    <Form.Control
                      type="text"
                      name="prefix"
                      value={formData.prefix}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group> */}
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button type="submit" variant="primary">
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Form>
              ) : (
                <>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{formData.name || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{formData.email || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Designation</th>
                        <td>{formData.designationName || "N/A"}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                    {/* <Button variant="danger" onClick={openDeleteConfirmation}>
                      Delete
                    </Button> */}
                  </Modal.Footer>
                </>
              )}
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this company?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
function AddCompanyModal({ show, handleClose, setCompanyList }) {
  const [designation, setDesignation] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    designation: "",
    Staff: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    console.log(formData);
    e.preventDefault();
    axiosInstance.post(LogisticsCreateUser, formData).then(
      (response) => {
        console.log(response.data);
        setCompanyList((prevList) => {
          console.log("Previous List:", prevList);
          return {
            ...prevList,
            count: prevList.count + 1,
            results: [response.data, ...prevList.results],
          };
        });
        toast.success("User Created Successfully ");
        handleClose();
      },
      (error) => {
        console.log(error);
        if (error?.response?.data?.email) {
          toast.error(error?.response?.data?.email[0]);
        }
      }
    );
  };
  useEffect(() => {
    axiosInstance.get(`${LogisticsUserDesignations}`).then(
      (response) => {
        console.log(response.data);
        setDesignation(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  const designationList = designation?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));
  const handleSelectChange = (name, value, label) => {
    const selectedValue = name === "designation" ? value : label;
    console.log(selectedValue);
    setFormData({ ...formData, [name]: selectedValue });
  };
  return (
    <Modal centered size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>
              Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>
              Email <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>
              Password<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="password"
              value={formData.password}
              onChange={handleChange}
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formClient" className="mt-2">
            <div className="input-block mb-3 row align-items-center">
              <label className="col-lg-3 col-form-label">Designation </label>
              <div className="col-lg-8">
                <Select
                  className="form-control"
                  options={designationList}
                  placeholder="Select designation"
                  onChange={(value, option) =>
                    handleSelectChange("designation", value, option.label)
                  }
                />
              </div>
            </div>
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const Users = () => {
  const [show, setShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [mappedUsers, setMappedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSuperuserModal, setShowSuperuserModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const { designation } = useSelector((state) => state.account);
  const Swal = withReactContent(MySwal);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleAddModalClose = () => setShowAddModal(false);
  const handleAddModalShow = () => setShowAddModal(true);

  useEffect(() => {
    fetchUsers(currentPage);
  }, [currentPage]);

  useEffect(() => {
    axiosInstance.get(`${LogisticsUserDesignations}`).then(
      (response) => {
        console.log("API Response:", response.data);
        setDesignationData(response.data);
      },
      (error) => {
        console.log("API Error:", error);
      }
    );
  }, []);

  useEffect(() => {
    if (designationData.results && companiesList.results) {
      setMappedUsers(mapUsersToDesignations(companiesList, designationData));
    }
  }, [designationData, companiesList]);

  const fetchUsers = (page) => {
    axiosInstance.get(`${LogisticsUserUrl}?page=${page}`).then(
      (response) => {
        console.log(response.data);
        setCompaniesList(response.data);
        setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
      },
      (error) => {
        console.log(error);
      }
    );
  };

  function mapUsersToDesignations(users, designations) {
    const designationLookup = designations.results?.reduce(
      (acc, designation) => {
        acc[designation.id] = designation.name;
        return acc;
      },
      {}
    );

    if (users.results) {
      return users.results.map((user) => ({
        ...user,
        designationName: user.designation
          ? designationLookup[user.designation]
          : null,
      }));
    }
    return [];
  }

  const updateCompanyInList = (updatedCompany) => {
    setCompaniesList((prevList) =>
      prevList?.map((company) =>
        company.id === updatedCompany.id ? updatedCompany : company
      )
    );
  };

  const removeCompanyFromList = (id) => {
    setCompaniesList((prevList) =>
      prevList.filter((company) => company.id !== id)
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const showConfirmationAlert = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonText: "Yes, reset it!",
      confirmButtonColor: "#FF902F",
      cancelButtonText: "Cancel",
      cancelButtonColor: "#FC133D",
    }).then((result) => {
      if (result.isConfirmed) {
        axiosInstance
          .post(`/accounts/admin-reset-password/`, { user_id: userId })
          .then(() => {
            Swal.fire({
              title: "Reset!",
              text: "Password has been reset.",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          })
          .catch((error) => {
            console.error("Password reset error:", error);
            Swal.fire({
              title: "Error!",
              text: "There was a problem resetting the password.",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          });
      } else {
        Swal.fire({
          title: "Cancelled",
          text: "Password reset was cancelled.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }
    });
  };

  const showSuperuserConfirmation = (userId) => {
    setSelectedUserId(userId);
    setShowSuperuserModal(true);
  };

  const handleSuperuserConfirm = () => {
    console.log(selectedUserId);
    axiosInstance
      .patch(`/accounts/users/update/${selectedUserId}/`, { superuser: true })
      .then((response) => {
        console.log(response.data);
        Swal.fire({
          title: "Success!",
          text: "User has been made a superuser.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
        setShowSuperuserModal(false);
        fetchUsers(currentPage); // Refresh the users list
      })
      .catch((error) => {
        console.error("Superuser error:", error);
        Swal.fire({
          title: "Error!",
          text: "There was a problem making the user a superuser.",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      });
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h2 className="card-title">Users List</h2>

            <div className="d-flex">
              <Button
                onClick={handleAddModalShow}
                variant="primary"
                className="btn btn-primary btn-sm p-2 d-flex align-items-center"
              >
                <PlusCircle
                  className="feather-download me-1"
                  data-bs-toggle="tooltip"
                  title="feather-download"
                />
                Add User
              </Button>
            </div>
          </div>
          <Table striped bordered hover className="text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Designation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {mappedUsers?.length > 0 ? (
                mappedUsers?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * 20 + index + 1}</td>
                    <td>{item.name || "Not available"}</td>
                    <td>{item.email || "Not available"}</td>
                    <td>{item.designationName || "Not available"}</td>
                    {designation === "ADMIN" && (
                      <td>
                        <Button
                          variant="danger"
                          className="btn btn-sm ms-1"
                          onClick={() => showConfirmationAlert(item.id)}
                        >
                          Reset Password
                        </Button>
                        {item?.superuser === false ? (
                          <Button
                            variant="warning"
                            className="btn btn-sm ms-1"
                            onClick={() => showSuperuserConfirmation(item.id)}
                          >
                            Make Superuser
                          </Button>
                        ) : (
                          <Button
                            variant="Success "
                            className="btn btn-sm ms-1 "
                            disabled
                          >
                            Superuser
                          </Button>
                        )}
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5">
                    loading...
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
          <AddCompanyModal
            show={showAddModal}
            handleClose={handleAddModalClose}
            setCompanyList={setCompaniesList}
          />
        </div>
      </div>

      <Modal
        show={showSuperuserModal}
        onHide={() => setShowSuperuserModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Superuser Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to make this user a superuser? This action
          cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSuperuserModal(false)}
          >
            Cancel
          </Button>
          <Button variant="warning" onClick={handleSuperuserConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Users;
