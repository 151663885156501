export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "OutWard List",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/outward-list",
        icon: "la la-exchange-alt", // Outward exchange icon
      },
      {
        menuValue: "Pending Approvals",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/approve-outward",
        icon: "la la-check-circle", // Check icon for approvals
      },
      {
        menuValue: "Inward Form",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/inward-form",
        icon: "la la-file-alt", // Inward form icon
      },
      {
        menuValue: "Inward List",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/inward-form-list",
        icon: "la la-list-ul", // List icon
      },
      {
        menuValue: "Purchase Order",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/inward-purchase-order",
        icon: "la la-shopping-cart", // Purchase order icon
      },
      {
        menuValue: "Purchase Order List",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/inward-purchase-order-list",
        icon: "la la-list-alt", // List icon for Purchase Order List
      },
      {
        menuValue: "Companies",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/logistics-companies",
        icon: "la la-building", // Building icon for companies
      },
      {
        menuValue: "Products",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/logistics-products",
        icon: "la la-boxes", // Product boxes icon
      },
      {
        menuValue: "Clients",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/logistics-clients",
        icon: "la la-user-friends", // Users icon for clients
      },
      {
        menuValue: "Users",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/users",
        icon: "la la-users-cog", // Users settings icon for admin users
      },
      {
        menuValue: "Warehouse & Storage",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/logistics-warehouse",
        icon: "la la-warehouse", // Warehouse icon
      },
      {
        menuValue: "Add Payment",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/add-payment",
        icon: "la la-credit-card", // Credit card icon for payments
      },
      {
        menuValue: "Payments List",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/payment-list",
        icon: "la la-list-ol", // Numbered list icon for payment list
      },
      {
        menuValue: "PO Stocks",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/purchase-order-stocks",
        icon: "la la-box-open", // Open box icon for PO stocks
      },
      {
        menuValue: "Reports",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-chart-bar", // Bar chart icon for reports
        subMenus: [
          {
            menuValue: "Stock Report",
            route: "/stock-report",
            icon: "la la-chart-line", // Line chart icon for stock report
          },
          {
            menuValue: "Outstanding Report",
            route: "/outstanding-report",
            icon: "la la-file-invoice-dollar", // Invoice icon for outstanding report
          },
        ],
      },
    ],
  },
];
