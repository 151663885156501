import React, { useState } from "react";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { axiosInstance } from "../../helpers/axios/axios";
import { LogisticsReverseDocket } from "../../helpers/endpoints/api_endpoints";

const DocketModal = ({ show, handleClose, CompanyList }) => {
  const [selectedCompany, setSelectedCompany] = useState(""); // To store selected company ID
  const [count, setCount] = useState("");
  const [reservedDockets, setReservedDockets] = useState(null);
  const [loading, setLoading] = useState(false);

  // Function to reserve docket numbers
  const reserveDocketNumbers = async (companyId, count) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `${LogisticsReverseDocket}${companyId}/${count}/`,
        {}
      );
      const reservedNumbers = response.data.reserved_docket_numbers;
      const firstNumber = reservedNumbers[0];
      const lastNumber = reservedNumbers[reservedNumbers.length - 1];
      setReservedDockets({ firstNumber, lastNumber, reservedNumbers });
    } catch (error) {
      console.error("Error reserving docket numbers:", error);
    } finally {
      setLoading(false);
    }
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedCompany && count) {
      reserveDocketNumbers(selectedCompany, count);
    }
  };

  // Reset function to clear all form fields and reset the state
  const handleReset = () => {
    setSelectedCompany("");
    setCount("");
    setReservedDockets(null);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reserve Docket Numbers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Dropdown for selecting company */}
          <Form.Group controlId="companyId">
            <Form.Label>Select Company</Form.Label>
            <Form.Control
              as="select"
              value={selectedCompany}
              onChange={(e) => setSelectedCompany(e.target.value)}
              required
            >
              <option value="">Choose a company...</option>
              {CompanyList?.map((company) => (
                <option key={company.value} value={company.value}>
                  {company.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* Input for count */}
          <Form.Group controlId="count" className="mt-3">
            <Form.Label>Count</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter count"
              value={count}
              onChange={(e) => setCount(e.target.value)}
              required
            />
          </Form.Group>

          {/* Reserve button */}
          <Button
            variant="primary"
            type="submit"
            className="mt-3"
            disabled={loading}
          >
            {loading ? "Reserving..." : "Reserve"}
          </Button>

          {/* Reset button */}
          <Button
            variant="warning"
            type="button"
            className="mt-3 ms-2"
            onClick={handleReset}
            disabled={loading}
          >
            Reset
          </Button>
        </Form>

        {/* Display reserved docket numbers */}
        {reservedDockets && (
          <div className="mt-4">
            <Card>
              <Card.Header className="bg-primary text-white">
                <h5>Docket Numbers Reserved Successfully</h5>
              </Card.Header>
              <Card.Body>
                <Card.Text className="text-center">
                  <strong>First Docket Number:</strong>{" "}
                  <span className="text-success">
                    {reservedDockets.firstNumber}
                  </span>
                </Card.Text>
                <Card.Text className="text-center">
                  <strong>Last Docket Number:</strong>{" "}
                  <span className="text-danger">
                    {reservedDockets.lastNumber}
                  </span>
                </Card.Text>
                <Card.Text className="text-center mt-3">
                  <small>
                    Total Reserved Docket Numbers:{" "}
                    {reservedDockets.reservedNumbers.length}
                  </small>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DocketModal;
