import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../helpers/axios/axios";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Loader, PlusCircle } from "react-feather";
import {
  LogisticsCompaniesURL,
  LogisticsWarehouse,
} from "../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { Select } from "antd";
import StorageLocationModal from "./StorageLocationModal";
import { Link } from "react-router-dom";
import StorageLocation from "./StorageLocation";

function ViewDetailsModal({
  show,
  handleClose,
  updateCompanyInList,
  removeCompanyFromList,
}) {
  const [formData, setFormData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [companyList, setCompaniesList] = useState([]);
  useEffect(() => {
    if (show) {
      axiosInstance.get(`${LogisticsWarehouse}${show}/`).then(
        (response) => {
          setFormData(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set isSubmitting to true
    axiosInstance.patch(`${LogisticsWarehouse}${show}/`, formData).then(
      (response) => {
        updateCompanyInList(response.data);
        setIsSubmitting(false); // Set isSubmitting to false
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false); // Set isSubmitting to false in case of error
        if (error?.response?.data?.name?.[0]) {
          toast.error(error?.response?.data?.name?.[0]);
        }
      }
    );
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };
  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };
  const indianStates = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
  ];
  useEffect(() => {
    axiosInstance.get(`${LogisticsCompaniesURL}`).then(
      (response) => {
        setCompaniesList(
          response.data.results.map((company) => ({
            value: company.id,
            label: company.name,
          }))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Warehouse Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>
                      Address<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      required
                      maxLength={100}
                      minLength={1}
                    />
                  </Form.Group>
                  <Form.Group controlId="formContactName">
                    <Form.Label>
                      Location<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formState">
                    <Form.Label>
                      State<span className="text-danger">*</span>
                    </Form.Label>
                    <Select
                      className="form-control"
                      options={indianStates}
                      placeholder={formData.state}
                      onChange={(value) => handleSelectChange("state", value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPrefix">
                    <Form.Label>Company Name</Form.Label>
                    <Select
                      className="form-control"
                      options={companyList}
                      placeholder={formData.company_details?.name}
                      onChange={(value) => handleSelectChange("company", value)}
                      required
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Save"}
                    </Button>
                  </Modal.Footer>
                </Form>
              ) : (
                <>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <th>Company Name</th>
                        <td>{formData.company_details?.name || "N/A"}</td>
                      </tr>

                      <tr>
                        <th>Address</th>
                        <td>{formData.address || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>{formData.location || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>state</th>
                        <td>{formData.state || "N/A"}</td>
                      </tr>

                      <tr>
                        <th>Created</th>
                        <td>{formData.created.slice(0, 10) || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Updated at</th>
                        <td>{formData.updated.slice(0, 10) || "N/A"}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this company?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function AddWarehouseModal({
  show,
  handleClose,
  setCompanyList,
  setUpdateStorage,
}) {
  const [formData, setFormData] = useState({
    state: "",
    company: null,
    address: "",
    location: "",
    is_active: true,
  });
  const [companyList, setCompaniesList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const indianStates = [
    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
  ];

  useEffect(() => {
    if (show) {
      setFormData({
        state: "",
        company: null,
        address: "",
        location: "",
        is_active: true,
      });
    }
  }, [show]);

  useEffect(() => {
    axiosInstance.get(`${LogisticsCompaniesURL}`).then(
      (response) => {
        setCompaniesList(
          response.data.results.map((company) => ({
            value: company.id,
            label: company.name,
          }))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    axiosInstance.post(LogisticsWarehouse, formData).then(
      (response) => {
        console.log(response.data);

        setCompanyList((prevList) => {
          // Ensure prevList exists and is iterable
          const updatedList = prevList?.results ? [...prevList.results] : [];
          return {
            ...prevList,
            count: (prevList?.count || 0) + 1,
            results: [response.data, ...updatedList],
          };
        });
        setUpdateStorage(response.data);
        setIsSubmitting(false);
        toast.success("Successfully added warehouse");
        handleClose();
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false);
        if (error?.response?.data?.name?.[0]) {
          toast.error(error?.response?.data?.name?.[0]);
        }
      }
    );
  };

  return (
    <Modal centered size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Warehouse</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formState">
            <Form.Label className="mb-0">
              State<span className="text-danger">*</span>
            </Form.Label>
            <Select
              className="form-control"
              options={indianStates}
              placeholder="Select State"
              onChange={(value) => handleSelectChange("state", value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formState">
            <Form.Label className="mb-0 mt-2">
              Company Name<span className="text-danger">*</span>
            </Form.Label>
            <Select
              className="form-control"
              options={companyList}
              placeholder="Select Company Name"
              onChange={(value) => handleSelectChange("company", value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="formAddress">
            <Form.Label className="mb-0 mt-2">
              Address <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formLocation">
            <Form.Label className="mb-0 mt-2">
              Location<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formIsActive" className="mb-0 mt-2">
            <Form.Check
              type="checkbox"
              label="Is Active"
              name="is_active"
              checked={formData.is_active}
              onChange={(e) =>
                handleSelectChange("is_active", e.target.checked)
              }
            />
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
const Warehouse = () => {
  const [show, setShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showStorageModal, setShowStorageModal] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
  const [updateStorage, setUpdateStorage] = useState([]);
  console.log(updateStorage);
  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);
  const handleAddModalClose = () => setShowAddModal(false);
  const handleAddModalShow = () => setShowAddModal(true);
  const handleStorageModalClose = () => setShowStorageModal(false);
  const handleStorageModalShow = (id) => {
    setSelectedWarehouseId(id); // Store the selected warehouse ID
    setShowStorageModal(true); // Show the modal
  };

  useEffect(() => {
    fetchCompanies(currentPage);
  }, [currentPage]);

  const fetchCompanies = (page) => {
    axiosInstance.get(`${LogisticsWarehouse}?page=${page}`).then(
      (response) => {
        console.log(response.data);
        setCompaniesList(response.data);
        setTotalPages(Math.ceil(response.data.count / 20));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const updateCompanyInList = (updatedCompany) => {
    setCompaniesList((prevList) => {
      return {
        ...prevList,
        results: prevList?.results?.map((outward) =>
          outward.id === updatedCompany.id ? updatedCompany : outward
        ),
      };
    });
  };
  // const updateStorageList = (updateStorage) => {
  //   setCompaniesList((prevList) => {
  //     return {
  //       ...prevList,
  //       results: prevList?.results?.map((outward) =>
  //         outward.id === updateStorage.id ? updateStorage : outward
  //       ),
  //     };
  //   });
  // };
  const removeCompanyFromList = (id) => {
    setCompaniesList((prevList) =>
      prevList?.results?.filter((company) => company.id !== id)
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />

      <div className="page-wrapper">
        <div className="card bg-white">
          <div className="card-header">
            <h5 className="card-title">Warehouse & Storages</h5>
          </div>
          <div className="card-body">
            <ul className="nav nav-tabs nav-tabs-top nav-justified w-50">
              <li className="nav-item">
                <Link
                  className="nav-link active text-bold"
                  to="#top-justified-tab1"
                  data-bs-toggle="tab"
                >
                  Warehouse
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-bold"
                  to="#top-justified-tab2"
                  data-bs-toggle="tab"
                >
                  Storages
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane show active" id="top-justified-tab1">
                <div className="content container-fluid">
                  <div className="d-flex justify-content-between align-items-center mb-1">
                    <Button
                      onClick={handleAddModalShow}
                      variant="primary"
                      className="btn btn-primary btn-sm p-2 d-flex align-items-center"
                    >
                      <PlusCircle
                        className="feather-download me-1"
                        data-bs-toggle="tooltip"
                        title="feather-download"
                      />
                      Add Warehouse
                    </Button>
                  </div>
                  <Table striped bordered hover className="text-center">
                    <thead className="table-secondary">
                      <tr>
                        <th>No.</th>
                        <th>Company Name</th>
                        <th>Address</th>
                        <th>State</th>
                        <th>Location</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companiesList?.count > 0 ? (
                        companiesList?.results?.map((item, index) => (
                          <tr key={item.id}>
                            <td>{(currentPage - 1) * 10 + index + 1}</td>
                            <td>
                              {item.company_details?.name || "Not available"}
                            </td>
                            <td>{item.address || "Not available"}</td>
                            <td>{item.state || "Not available"}</td>
                            <td>{item.location || "Not available"}</td>
                            <td>
                              {item?.created?.slice(0, 10) || "Not Available"}
                            </td>
                            <td>
                              <Button
                                variant="primary"
                                className="btn btn-sm ms-1"
                                onClick={() => handleShow(item.id)}
                              >
                                Warehouse Details
                              </Button>
                              <Button
                                variant="primary"
                                className="btn btn-sm ms-1"
                                onClick={() => handleStorageModalShow(item.id)}
                              >
                                Add Storage Location
                              </Button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="7">
                            {" "}
                            loading...{" "}
                            <Loader
                              className="feather-loader"
                              data-bs-toggle="tooltip"
                              title="feather-loader"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="d-flex justify-content-between">
                    <Button
                      variant="secondary"
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <span>
                      Page {currentPage} of {totalPages}
                    </span>
                    <Button
                      variant="secondary"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Button>
                  </div>
                  <ViewDetailsModal
                    show={show}
                    handleClose={handleClose}
                    updateCompanyInList={updateCompanyInList}
                    removeCompanyFromList={removeCompanyFromList}
                  />
                  <AddWarehouseModal
                    show={showAddModal}
                    handleClose={handleAddModalClose}
                    setCompanyList={setCompaniesList} // Make sure this updates the warehouse list
                  />
                  <StorageLocationModal
                    show={showStorageModal}
                    handleClose={handleStorageModalClose}
                    setCompanyList={setCompaniesList} // Update warehouse list with storage location
                    warehouseId={selectedWarehouseId} // Pass the warehouse ID to the modal
                    setUpdateStorage={setUpdateStorage}
                  />
                </div>
              </div>
              <div className="tab-pane" id="top-justified-tab2">
                <StorageLocation
                  updateStorage={updateStorage}
                  setCompanyList={setCompaniesList}
                  // updateStorageList={updateStorageList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Warehouse;
