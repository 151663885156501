import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../helpers/axios/axios";
import { LogisticsStorage } from "../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

const StorageLocationModal = (props) => {
  const { show, handleClose, warehouseId, setUpdateStorage } = props;
  console.log(warehouseId);
  const [formData, setFormData] = useState({
    storage_location: "",
    warehouse: warehouseId,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      setFormData({
        storage_location: "",
        warehouse: warehouseId,
      });
    }
  }, [show, warehouseId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    axiosInstance.post(LogisticsStorage, formData).then(
      (response) => {
        console.log(response.data);
        setUpdateStorage(response.data);
        setUpdateStorage((prevList) => {
          const updatedList = prevList?.results ? [...prevList.results] : [];
          return {
            ...prevList,
            count: (prevList?.count || 0) + 1,
            results: [response.data, ...updatedList],
          };
        });

        setIsSubmitting(false);
        toast.success("Successfully added Storage Location");
        handleClose();
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false);
        if (error?.response?.data?.name?.[0]) {
          toast.error(error?.response?.data?.name?.[0]);
        }
      }
    );
  };

  return (
    <Modal centered size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Storage Location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formLocation">
            <Form.Label>Storage Location</Form.Label>
            <Form.Control
              type="text"
              name="storage_location"
              value={formData.storage_location}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Save"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default StorageLocationModal;
